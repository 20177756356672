import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH, MOBILE_QUERY_MAX_WIDTH_M } from "/constants/vp-sizes";
export const StyledSecurityLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .security-logo-wrapper {
    &:not(:last-child) {
      margin-right: 24px;

      @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
        margin-right: 20px;
      }

      @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
        margin-right: 16px;
      }
    }

    .security-logo {
      height: 72px;

      &.small-logo {
        height: 48px;
      }
    }
  }
`;
