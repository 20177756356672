import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const StyledLogosAndHeaderSecurityComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  padding: 0px 32px;
  text-align: center;

  .security-logos-wrapper {
    margin-top: 24px;
  }
`;
